<template>
    <PageBlock 
        title="Benchmarks"
        :can-fullscreen="false"
        is-table
        :list="list">

        <vxe-table-column 
            field="ind_id" 
            title="Index" 
            min-width="120">
        </vxe-table-column>

        <vxe-table-column 
            title="Piece" 
            width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.ind_percent"
                    format="0.00%"/>
            </template>
        </vxe-table-column>
            
    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'
export default {
    name: 'portfolio_benchmarks',
    components: { PageBlock, FormattedNumber },

    props: {
        portfolio: {
            required: true,
        },
    },

    data(){
        return {
            list: this.portfolio.benchmarks || [],
        }
    }
}
</script>
